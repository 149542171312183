import React, { Fragment } from "react";

const notfound = () => {
    return (
        <Fragment>
            <h1>NOT FOUND</h1>
        </Fragment>
    );
};

export default notfound;
