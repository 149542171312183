export const socialsList = [
    {
        href: "https://github.com/miwoju",
        fontAwesomeClassName: "fab fa-github",
    },
    // { href: "", fontAwesomeClassName: "fab fa-twitter" },
    {
        href: "https://www.instagram.com/miwoju/",
        fontAwesomeClassName: "fab fa-instagram",
    },
    {
        href: "https://www.tiktok.com/@miwoju",
        fontAwesomeClassName: "fab fa-tiktok",
    },
];
